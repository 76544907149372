<template>
  <v-container>
    <v-app-bar clipped-left color="navbar" class="font-weight-medium" app elevation="0">
      <v-row no-gutters justify="center" align="center">
        <v-col cols="10">
          <v-img :src="require('@/assets/applia_logo.png')" class="my-3 ml-1" contain width="100" />
        </v-col>
        <v-col cols="6" align="right" sm="2">
          <v-menu offset-y bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed large title="Brugerinfo" icon v-bind="attrs" v-on="on" :color="azureAccount ? 'activeButton' : 'button'">
                <v-icon v-if="!$vuetify.breakpoint.mobile">mdi-account-circle</v-icon>
                <v-icon v-else>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <user-menu @show-login="showLoginDialog = true" @show-changePassword="$refs.ChangePassRef.changePassword(false)" />
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-main class="px-12">
      <left-nav-bar />
      <router-view class="pa-0" />
    </v-main>
  </v-container>
</template>

<script>
import LeftNavBar from '../components/LeftNavBar.vue';
import UserMenu from '../components/UserMenu.vue';
import { mapGetters } from 'vuex';
export default {
  name: 'Home',
  components: { LeftNavBar, UserMenu },
  computed: {
    ...mapGetters(['azureAccount', 'accountContacts', 'accountGroups']),
  },
  created() {
    this.$store.dispatch('LOAD_SAVED_ACCOUNT');
    this.$store.dispatch('LOAD_GROUPS_AND_CONTACTS', this.azureAccount);
  },
};
</script>
