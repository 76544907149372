<template>
  <v-navigation-drawer class="pa-3" clipped app v-if="azureAccount">
    <v-btn class="mr-2 mb-2" plain :to="{ name: 'Contacts' }">Kontakter</v-btn>
    <v-btn class="mr-2 mb-2" plain :to="{ name: 'Distributionslister' }">Grupper</v-btn>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['azureAccount']),
  },
  data: () => ({}),
};
</script>

<style>
</style>