<template>
  <div>
    <div v-if="azureAccount" class="text-center">
      Logget ind som <br />
      <strong> {{ azureAccount.name }} </strong>
    </div>
    <v-list>
      <v-list-item v-if="azureAccount" @click="$store.dispatch('LOGOUT_AZURE')">
        <v-list-item-title>
          <v-icon left>mdi-logout</v-icon>
          Log ud
        </v-list-item-title>
      </v-list-item>
      <v-list-item v-else @click="$store.dispatch('OPEN_AZURE_AUTH')">
        <v-list-item-title>
          <v-icon left>mdi-login</v-icon>
          Log ind
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'user-menu',
  computed: {
    ...mapGetters(['azureAccount']),
  },
};
</script>

<style></style>
